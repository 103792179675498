





















import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'AsyncTeachers',
  setup() {
    const teacherList = [
      {
        avatar:
          'https://zyb-zhike-file.cdnjtzy.com/mis/9c42bea8-fcfe-40a1-ba4b-116ed5b360db.png',
        name: '夏焱',
        desc: `<span>20+项吉尼斯世界纪录创造者</span>`
      },
      {
        avatar:
          'https://zyb-zhike-file.cdnjtzy.com/mis/88ebdaf6-eac9-4bc0-98c9-b2b97066dd9a.png',
        name: '关丽',
        desc: `<span>非物质文化遗产</span><span>剪纸项目代表性传承人</span>`
      },
      {
        avatar:
          'https://zyb-zhike-file.cdnjtzy.com/mis/082e7773-b10f-4b83-9486-daa070649a47.png',
        name: '王杜',
        desc: `<span>中科院工程热物理</span><span>研究所博士后</span>`
      },
      {
        avatar:
          'https://zyb-zhike-file.cdnjtzy.com/mis/10c2d951-cae6-45c6-a955-e403a4ee008b.png',
        name: '张武祥',
        desc: `<span>中国书法文化研究院</span><span>首都师范大学书法专业硕士</span>`
      },
      {
        avatar:
          'https://zyb-zhike-file.cdnjtzy.com/mis/cd20c013-7f3c-429f-a7b6-62dad7781cdb.png',
        name: '于赓哲',
        desc: `<span>陕西师大</span><span>著名历史教授</span>`
      }
    ]

    const avtiveIndex = ref(0)
    let activeTimer = 0

    const handleMouseEnter = (index: number) => {
      activeTimer && window.clearInterval(activeTimer)
      avtiveIndex.value = index
    }

    // 自动轮播
    const startCarousel = () => {
      let conter = avtiveIndex.value
      activeTimer && window.clearInterval(activeTimer)
      activeTimer = window.setInterval(() => {
        ++conter
        avtiveIndex.value = conter % teacherList.length
        conter = avtiveIndex.value
      }, 3000)
    }

    startCarousel()

    return {
      teacherList,
      handleMouseEnter,
      avtiveIndex,
      startCarousel
    }
  }
})
